<template>
  <v-container id="regular-tables" fluid tag="section">
    <base-v-component heading="Edit Branch"/>
    <base-material-card icon="mdi-domain" title="Edit Branch" class="px-5 py-3">
      <v-form ref="form" @submit="submit" onSubmit="return false;">
        <v-container class="py-0">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="branch.name"
                label="Branch Name"
                :rules="rules.name"
                :loading="this.isLoading"
                :disabled="this.isLoading"
                placeholder="Branch Name"
                outlined
                required/>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn
                color="success"
                class="mr-0"
                @click="submit"
                :loading="this.isLoading"
                :disabled="this.isLoading">
                Edit Branch
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>


<script>

  export default {
    name: "EditBranch",
    created() {
      this.getBranch();
    },
    data() {
      return {
        isLoading: true,
        branch: {
          id: '',
          name: ''
        },
        rules: {
          name: [
            v => !!v || 'Name is required',
            v => v.length >= 3 || 'Name must be at least 3 characters',
          ],
        },
      }
    },
    methods: {
      getBranch() {

        this.$authApi.get(`/branches/${this.$route.params.id}`)
          .then(res => {
            this.branch = res.data.data;
            this.isLoading = false;
          }).catch(error => {
          this.flashMessage.error({
            title: 'Cant Edit this branch',
          });
          this.$router.push('/branches')
        })
      },
      submit() {
        if (!this.$refs.form.validate()) {
          return;
        }
        this.$authApi.put(`/branches/${this.branch.id}`, this.branch)
          .then(res => {
            this.flashMessage.success({
              title: 'Branch Edited Successfully',
              message: `Your branch ${this.branch.name} has been Edited successfully`
            });
            this.$router.push('/branches')

          }).catch(error => {
          if (error.response) {
            this.flashMessage.error({
              title: 'Something went wrong ',
              message: error.response.data.errors.name.join(',')
            });
          }
        })
      },

    }
  }
</script>
